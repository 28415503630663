.ant-progress-line {
    width: auto;

    .ant-progress-outer {
        width: auto;
    }

    .ant-progress-inner {
        width: 15vw;
    }

    .ant-progress-text {
        width: auto;
    }
}
@primary-color: #13c2c2;