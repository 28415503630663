@import '~antd/dist/antd.less';
@import '~@ant-design/pro-form/dist/form.less';
@import '~@ant-design/pro-table/dist/table.less';
@import '~@ant-design/pro-layout/dist/layout.less';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.ant-pro-page-container-extraContent {
    margin-left: 0;
    width: 40%;
}

// In order to solve the problem that search button would be ugly after search failed.
span.ant-input-group-addon {
    padding: 0;
    border: 0;
}

// In order to solve the problem that long text will destory the structure of <Descriptions />.
td.ant-descriptions-item-content {
    width: 70%;
}
@primary-color: #13c2c2;