.background {
    background-image: url('../assets/images/LoginBG.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 80%;
    background-color: #FFFFFF;
}

.footer {
    background-color: rgba(255, 255, 255, 0.0);
}
@primary-color: #13c2c2;